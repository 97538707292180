<template>
  <div class="item" @click="onClick($router)">
    <div class="item-icon">
      <slot name="icon">
        <i class="zm-icon zm-icon-jianyi"></i>
      </slot>
    </div>
    <div class="item-title">{{ title }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  url: String
})
const onClick = ($router) => {
  if (props.url) {
    $router.push(props.url)
  }
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 170px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px 6px 6px 6px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    // background: #FFFFFF;
    border-radius: 50%;
    // border: 1px solid #D9D9D9;
    .zm-icon {
      font-size: 85px;
      color: #1A76D1;
    }
  }
  &-title {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
}
</style>