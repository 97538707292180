<template>
  <div class="intro-card-list">
    <div class="item" v-for="item in list" :key="item.AdID" @click="onItemClick(item)">
      <!-- <i class="zm-icon zm-icon-yewuzongliang"></i> -->
      <img class="icon" :src="`${setting.baseUrl.img}/${item.PicUrl}`">
      <h3 class="title">{{ item.AdName }}</h3>
      <p class="des">
        {{ item.Info }}
      </p>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import * as commonApi from '@/api/common'
export default {
  data() {
    return {
      setting,
      list: []
    }
  },
  methods: {
    async getList() {
      let { status, rows } = await commonApi.getBannerAndIntro('intro')
      if (status) {
        return
      }
      this.list = rows;
    },
    onItemClick(item) {
      if (item.linkUrl) {
        window.open(item.linkUrl, '_blank');
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
  .intro-card-list {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    .item {
      width: 200px;
      cursor: pointer;
      text-align: center;
      transition: all .2s;
      .zm-icon {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 54px;
        color: #4069a0;
      }
      img.icon {
        margin-bottom: 15px;
        height: 54px;
        width: 54px;
      }
      .title {
        margin-bottom: 6px;
        font-size: 18px;
        color: #4069a0;
        font-weight: lighter;
      }
      .des {
        font-size: 14px;
        color: #859196;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
</style>
