<template>
  <div class="sta-list m-w1200">
    <StaListItem title="数据总量" :num="data?.sample?.sjzl ?? 0">
      <template #icon>
        <i class="zm-icon zm-icon-tongji1"></i>
      </template>
    </StaListItem>
    <StaListItem title="样品数量" :num="data?.sample?.ztkx ?? 0">
      <template #icon>
        <i class="zm-icon zm-icon-keji"></i>
      </template>
    </StaListItem>
    <StaListItem title="文献收录" :num="data?.sample?.wxsl ?? 0">
      <template #icon>
        <i class="zm-icon zm-icon-shuji"></i>
      </template>
    </StaListItem>
    <StaListItem title="实验数据" :num="data?.sample?.sy ?? 0">
      <template #icon>
        <i class="zm-icon zm-icon-shiyan"></i>
      </template>
    </StaListItem>
    <!-- <StaListItem title="实验预约" :num="data?.reserve?.total ?? 0">
      <template #icon>
        <i class="zm-icon zm-icon-yuyue"></i>
      </template>
    </StaListItem> -->
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import * as commonApi from '@/api/common'
import StaListItem from './StaListItem.vue'

const list = ref([])
const data = computed(() => {
  if (!list.value.length) {
    return null
  }
  let reserves = list.value.filter(item => item.NameType === '预约统计');
  let samples = list.value.filter(item => item.NameType === '样例统计');
  let data = {
    reserve: {
      // 审批中
      auditing: (() => {
        let arr = reserves.filter(item => item.CDataName === '审批中').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 通过
      pass: (() => {
        let arr = reserves.filter(item => item.CDataName === '通过').map(item=> item.CRonCount)
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 实验中
      doing: (() => {
        let arr = reserves.filter(item => item.CDataName === '实验中').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 拒绝
      reject: (() => {
        let arr = reserves.filter(item => item.CDataName === '拒绝').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 实验结束
      done: (() => {
        let arr = reserves.filter(item => item.CDataName === '实验结束').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 总数
      total: (() => {
        let arr = reserves.map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })()
    },
    sample: {
      // 专题科学数据
      ztkx: (() => {
        let arr = samples.filter(item => item.CDataName === '专题科学数据').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 文献收录
      wxsl: (() => {
        let arr = samples.filter(item => item.CDataName === '文献收录').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 实验数据
      sy: (() => {
        let arr = samples.filter(item => item.CDataName === '实验数据').map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })(),
      // 数据总量
      sjzl: (() => {
      let arr = samples.filter(item => item.CDataName === '数据总量').map(item=> item.CRonCount);
      let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
      return pre + val
      })
      return total
      })(),
      // 总数
      total: (() => {
        let arr = samples.map(item=> item.CRonCount);
        let total = arr.length === 0 ? 0 : arr.reduce((pre, val) => {
          return pre + val
        })
        return total
      })()
    }
  }
  return data
})

onMounted(() => {
  getList()
})
// 获取列表
async function getList() {
  let { status, rows } = await commonApi.getStatisticsData()
  if(!status) {
    list.value = rows;
  }
}
</script>

<style lang="less" scoped>
.sta-list {
  display: flex;
  gap: 20px;
}  
</style>
