<template>
  <div class="banner-wrap">
    <el-carousel
      ref="carousel"
      class="m-banner"
      arrow="never"
      indicator-position="none"
      :interval="5000"
      trigger="click"
      :height="bannerHeight + 'px'">
      <el-carousel-item
        class="m-banner-item"
        v-for="item in bannerList"
        :key="item.AdID"
        :style="{
          'background-image': `url('${setting.baseUrl.img + item.PicUrl}')`
        }"
        @click.native="onBannerItemClick(item.linkUrl)"
      >
      </el-carousel-item>
    </el-carousel>
    <!-- <div class="control-wrap">
      <div class="control">
        <el-button-group>
          <el-button type="info" icon="el-icon-arrow-left" @click="prev"></el-button>
          <el-button type="danger" icon="el-icon-arrow-right" @click="next"></el-button>
        </el-button-group>
      </div>
    </div> -->
    <slot name="bottom"></slot>
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import setting from '@/setting'
export default {
  data() {
    return {
      setting,
      bannerList: [],
      bannerHeight: 500
    }
  },
  methods: {
    async getBanner() {
      let { status, rows } = await commonApi.getBannerAndIntro()
      if (status) {
        return
      }
      this.bannerList = rows;
    },
    onBannerItemClick(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },
    computedBannerHeight() {
      // let height = document.body.clientHeight - 300
      this.bannerHeight = 600
    },
    next() {
      this.$refs.carousel.next()
    },
    prev() {
      this.$refs.carousel.prev()
    }
  },
  created() {
    this.computedBannerHeight()
    this.getBanner()
  }
}
</script>

<style lang="less" scoped>
  .banner-wrap {
    position: relative;
    // margin-bottom: 85px;
    .control-wrap {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 99;
      .control {
        margin: 0 auto;
        text-align: right;
        /deep/ .el-button--info {
          background-color: rgba(144,147,153,.8);
          border: none;
          &:hover {
            background-color: rgba(144,147,153,.95);
          }
        }
        /deep/ .el-button--danger {
          background-color: rgba(245,108,108,.8);
          border:none;
          &:hover {
            background-color: rgba(245,108,108,.95);
          }
        }
      }
    }
  }
  .m-banner {
    background-color: #fff;
    .m-banner-item {
      background-size: cover;
      background-position: center;
      cursor: pointer;
      background-color: rgba(26, 118, 209, 1);
      // &:nth-of-type(1) {
      //   background-image: url('./images/1.jpg');
      // }
      // &:nth-of-type(2) {
      //   background-image: url('./images/2.jpg');
      // }
      // &:nth-of-type(3) {
      //   background-image: url('./images/3.jpg');
      // }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(23, 29, 36, .2);
      }
    }
    /deep/ .el-carousel__button {
      height: 4px;
    }
    &--1200 {
      margin: 30px auto 0 auto;
      width: 1200px;
    }
  }
</style>
