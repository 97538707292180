<template>
  <ul class="list">
    <li
      v-for="item in lab.list"
      :key="item.Id"
      class="item"
      @click="onLabItemClick(item)">
      <div class="item-name" :title="item.Title">{{ item.Title }}</div>
    </li>
  </ul>
</template>

<script>
import * as labApi from '@/api/Lab_Sys_Dept'
export default {
  data() {
    return {
      lab: {
        list: [],
        page: {
          index: 1,
          size: 9999,
          total: 0
        }
      }
    }
  },
  methods: {
    // 获取实验室列表
    async getLabList() {
      let { status, rows } = await labApi.getPageData({
        page: this.lab.page.index,
        rows: this.lab.page.size,
        sort: 'OrderNo',
        wheres:'[{"name":"Enable","value":"1","displayType":"switch"}]'
      })
      if(!status) {
        this.lab.list = rows;
      }
    },
    // 实验室点击
    onLabItemClick(item) {
      window.open(item.WebUrl, '_blank')
    }
  },
  created() {
    this.getLabList();
  }
}
</script>

<style lang="less" scoped>
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    li {
      flex: 0 0 210px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      width: 0;
      height: 50px;
      background: rgba(255,255,255,0.16);
      border-radius: 4px 4px 4px 4px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
      .item-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
</style>
