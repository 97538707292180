<template>
  <List>
    <ListItem
      v-for="item in data"
      :key="item.ID"
      :model="item"
      @click="onDetail(item)">
    </ListItem>
  </List>
</template>

<script>
import dThemeApi from '@/api/dTheme'
import setting from '@/setting'
import List from './child/List.vue'
import ListItem from './child/ListItem.vue'

export default {
  name: 'Search_dTheme',
  components: { List, ListItem },
  data() {
    return {
      loading: false,
      data: [],
      page: {
        index: 1,
        size: 3,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      this.loading = true
      dThemeApi.getPageData({
        page: this.page.index,
        rows: this.page.size
      }).then(res => {
        this.loading = false
        res.rows.forEach(item => {
          item.ex_pic = item.Pic ? setting.baseUrl.img + '/' + item.Pic : ''
        })
        this.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.loading = false
      })
    },
    async onDetail(item) {
      let { rows } = await dThemeApi.getDetailPage({
        page: 1,
        rows: 999999,
        value: item.ID
      })
      
      let sampleIds = rows.map(item => item.Sample_ID)
      let wheres = []
      wheres.push({name: 'Sample_ID', value: sampleIds.join(','), displayType: 'selectList'})
      let url = `/search/result?wheres=${JSON.stringify(wheres)}`
      console.log(url)
      this.$router.push(url)
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  
</style>
