<template>
  <NewListItem :model="model" />
</template>

<script>
import setting from '@/setting'
import util from '@/utils'
import newsApi from '@/api/news'
export default {
  data() {
    return {
      model: {}
    }
  },
  filters: {
    summary: function(val, maxLength=100, replaceChar='...') {
      if (!val) {return ''}
      let str = val.replace(/<.*?>|&nbsp;|&emsp;/g,"")
      if (str.length > maxLength) {
        str = str.substr(0, maxLength) + replaceChar
      }
      return str
    }
  },
  methods: {
    getData() {
      let wheres = [
        { name: 'NewsType', value: '2', displayType: 'select' }, // 1:行业新闻 2.科研动态
        { name: 'DailyRecommend', value: '1', displayType: 'select' } // 今日推荐
      ];
      newsApi.getPageData({
        page: 1,
        rows: 1,
        order: 'desc',
        sort: 'CreateDate',
        wheres: JSON.stringify(wheres)
      }).then(res => {
        if (res.rows && res.rows.length) {
          res.rows.forEach(item => {
            let images = item.ImageUrl ? item.ImageUrl.split(',') : []
            if (images.length) {
              let url = images[0]
              let isUrl = util.isUrl(url)
              item.ex_ImageUrl = isUrl ? url : setting.baseUrl.img + '/' + url
            }
          })
          this.model = res.rows[0]
        }
      })
    },
    onMore() {
      this.$router.push('/news/research')
    },
    onDetail(detailUrl) {
      this.$router.push(`/news/detail?detailUrl=${detailUrl}`)
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="less" scoped>
  .research {
    .new {
      display: flex;
      &_img {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 30px;
        height: 218px;
        width: 200px;
        background-position: center;
        background-size: cover;
        border-radius: 2px;
      }
      &_title {
        margin-bottom: 8px;
        line-height: 24px;
      }
      &_pubtime {
        margin-bottom: 12px;
        font-size: 14px;
      }
      &_summary {
        margin-bottom: 12px;
        font-size: 14px;
        color: #999;
        line-height: 23px;
      }
      &_action {
        text-align: right;
      }
    }
  }
</style>
