<template>
  <ul class="m-news-list">
    <li
      class="item"
      v-for="(item, idx) in list"
      :key="item.Id">
      <div class="item-l">
        <span class="item-idx">{{ idx+1 }}</span>
        <a
          class="item-title m-ellip"
          href="javascript:void(0)"
          @click="onDetail(item.DetailUrl)">
          {{ item.Title }}
        </a>
      </div>
      
      <span class="item-date">{{ item.CreateDate }}</span>
    </li>
  </ul>
</template>

<script>
import newsApi from '@/api/news'
export default {
  data() {
    return {
      list: []
    }
  },
  methods: {
    getData() {
      let wheres = [
        {name: 'NewsType', value: '1', displayType: 'select'} // 1:行业新闻 2.科研动态
      ];
      newsApi.getPageData({
        page: 1,
        rows: 4,
        order: 'desc',
        sort: 'CreateDate',
        wheres: JSON.stringify(wheres)
      }).then(res => {
        this.list = res.rows
      })
    },
    onMore() {
      this.$router.push('/news')
    },
    onDetail(detailUrl) {
      this.$router.push(`/news/detail?detailUrl=${detailUrl}`)
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-news {
    &-list {
      .item {
        padding: 32px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.09);
        &:last-child {
          margin-bottom: 0;
        }
        &-l {
          .item-idx {
            margin-right: 13px;
            padding: 4px 9px;
            background: rgba(26,118,209,0.06);
            border-radius: 6px 6px 6px 6px;
            font-weight: 500;
            font-size: 14px;
            color: #1A76D1;
            line-height: 16px;
            text-align: center;
            font-style: normal;
            text-transform: none;
          }
          .item-title {
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            text-decoration: none;
            transition: all .1s linear;
            &:hover {
              color: #2196f3;
            }
          }
        }
        
        &-date {
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 19px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
</style>
