<!-- 访客情况统计 -->
<template>
  <div class="visit-info">
    <div class="main">
      <div ref="map" class="map"></div>
      <div class="rank">
        <!-- <div class="title">访问统计</div> -->
        <ul class="list">
          <li v-for="(item, index) in rankList" :key="item.id">
            <span class="left">
              <span v-if="index <= 2" class="index" :style="{'background-color': color[index]}">{{ index + 1 }}</span>
              <span v-else class="index">{{ index + 1 }}</span>
              <span class="name">{{ item.name }}</span>
            </span>
            <span class="ratio">{{ item.count }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import 'echarts/map/js/world.js'
import 'echarts/map/js/china.js'
import config from './config'
import throttle from 'lodash/throttle'
import * as commonApi from '@/api/common'
export default {
  data() {
    return {
      chart: null,
      color: ['#F56C6C', '#E6A23C', '#67C23A'],
      rankList: [
        // {id: 1, name: '中国', ratio: 60},
        // {id: 2, name: '美国', ratio: 20},
        // {id: 3, name: '日本', ratio: 10},
        // {id: 4, name: '俄罗斯', ratio: 5},
        // {id: 5, name: '巴基斯坦', ratio: 3},
        // {id: 6, name: '澳大利亚', ratio: 3},
        // {id: 7, name: '菲律宾', ratio: 3}
      ]
    }
  },
  methods: {
    render() {
      let data = this.rankList.map((item, index) => {
        let color = '#5ca2ff';
        // let color = index <= 2 ? this.color[index] : '#909399';
        return {
          name: item.name,
          value: item.count,
          itemStyle: {
            areaColor: color
          }
        }
      })
      let option = {
        // backgroundColor: 'rgba(26, 118, 209, 0.06)',
        tooltip: {
          show: true,
          // formatter: function(data) {
          //   data.value = data.value || 0
          //   return `${data.value}%`
          // }
        },
        // visualMap: {
        //   min: 0,
        //   max: 100,
        //   left: 30,
        //   bottom: 30,
        //   text: ['高', '低'],
        //   inRange: {
        //     color: ['#e0ffff', '#006edd'],
        //     symbolSize: [30, 100]
        //   },
        //   formatter: function (val) {
        //       return `${val}%`;
        //   }
        // },
        // geo: {
        //   show: true,
        //   map: 'world',
        //   roam: true,
        //   nameMap: config.nameMap
        // },
        series: [
          {
            name: '访问次数',
            type: 'map',
            map: 'world',
            roam: false,
            nameMap: config.nameMap,
            data
          }
        ]
      }
      this.chart.setOption(option)
    },
    async getData() {
      let { status, rows } = await commonApi.getVisitData();
      if (status === 0) {
        this.rankList = rows.map(item => {
          return {
            id: item.id,
            name: item.CDataName,
            count: item.CRonCount
          }
        })
        this.render();
      }
    },
    // 屏幕宽度监听
    onWinResize: throttle(function () {
      this.chart.resize()
    }, 200)
  },
  mounted() {
    this.chart = echarts.init(this.$refs.map)
    window.addEventListener('resize', this.onWinResize)
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.main {
  display: flex;
  align-items: stretch;
  height: 400px;
  background-color: rgba(26, 118, 209, 0.06);
  border-radius: 6px;
  .map {
    flex: 1 1 auto;
  }
  .rank {
    flex: 0 0 300px;
    width: 0;
    background-color: #fff;
    border-left: 1px solid #eee;
    overflow: auto;
    .title {
      padding: 0 20px;
      line-height: 57px;
      font-size: 16px;
      border-bottom: 1px solid #eee;
    }
    .list {
      padding: 0 20px;
      li {
        display: flex;
        justify-content: space-between;
        line-height: 48px;
        &+li {
          border-top: 1px solid #eee;
        }
      }
      .name, .index, .ratio {
        display: inline-block;
      }
      .index {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        background-color: #909399;
        border-radius: 4px;
      }
      .ratio {
        width: 50px;
        text-align: center;
        color: #859196;
        font-size: 14px;
      }
    }
  }
}
</style>
