<template>
  <div>
    <Banner>
      <template #bottom>
        <FastTrack class="fast-track" />
      </template>
    </Banner>
    <div v-show="fixedSide" ref="sideLeft" :class="['side side--left', {
      'side--sticky': sideSticky
    }]">
      <SideLabList />
    </div>
    <div class="main">
      <div class="content">
        <!-- <div v-show="!fixedSide" class="section section-lab-sta section--white">
          <div class="lablist">
            <LabList />
          </div>
          <div class="sta">
            
          </div>
        </div> -->
        <!-- <Section>
          <IntroCardList />
        </Section> -->
        <Section :showMore="false" style="margin-top: 100px;">
          <Statistics />
        </Section>
    
        <Section
          title="专题"
          moreUrl="/journal"
          showMoreBorder
          backgroundColor="#fff">
          <Topic />
        </Section>
        <!-- <Section title="科研动态" moreUrl="/news/research">
          <Research />
        </Section> -->
        <Section
          title="新闻动态"
          moreUrl="/news"
          showMoreBorder
          backgroundColor="#fff">
          <News />
        </Section>
   
        <Section title="访问统计" :showMore="false">
          <VisitInfo />
        </Section>

        <!-- <Section
        v-show="!fixedSide"
        title="实验室"
        titleColor="#fff"
        :showMore="false"
        backgroundColor="#5ca2ff"
        :backgroundImage="LabBg">
        <LabList />
      </Section> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getScreenMode } from '@/utils'
import Banner from './child/banner'
import LabList from './child/lab/LabList.vue'
import SideLabList from './child/lab/SideLabList.vue'
import IntroCardList from './child/intro-card-list'
import Topic from './child/topic'
import News from './child/news'
import Research from './child/research'
import Statistics from './child/statistics/StaList.vue'
import FastTrack from './child/fastTrack/List.vue'
import VisitInfo from '@/components/visit-info'
import LabBg from '@/assets/images/bg.png'
export default {
  components: {
    Banner,
    LabList,
    SideLabList,
    IntroCardList,
    Topic,
    News,
    Research,
    VisitInfo,
    Statistics,
    FastTrack
  },
  data() {
    return {
      isSideInit: false,
      fixedSide: false, // 侧边栏是否两边
      sideSticky: false,
      LabBg
    }
  },
  methods: {
    sideInit() {
      console.log('sideInit')
      let that = this
      let el_main = document.querySelector('.m-main')
      let sideLeft = this.$refs['sideLeft']
      let sideRight = this.$refs['sideRight']
      if (!sideLeft || !sideRight) {
        return
      }
      let onscroll = function() {
        let scrollTop = el_main.scrollTop
        if (scrollTop >= 400 - 65) {
          sideLeft.style.top = `65px`
          sideRight.style.top = `65px`
          // that.sideSticky = true
        } else {
          sideLeft.style.top = `${400 - scrollTop}px`
          sideRight.style.top = `${400 - scrollTop}px`
          // that.sideSticky = false
        }
      }
      el_main.addEventListener('scroll', onscroll)
      this.$once('hook:beforeDestory', function() {
        el_main.removeEventListener('scroll', onscroll)
      })
    },
    resize() {
      let screenWidth = window.innerWidth;
      if (screenWidth >= 1800) {
        this.fixedSide = true;
        if (!this.isSideInit) {
          this.$nextTick(() => {
            this.sideInit();
            console.log('dd')
            this.isSideInit = true;
          })
        }
      } else {
        this.fixedSide = false;
      }
    }
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
    this.$once('hook:beforeDestory', function() {
      window.removeEventListener('resize', this.resize);
    })
  }
}
</script>

<style lang="less" scoped>
.side {
  position: fixed;
  top: 30vh;
  z-index: 99;
  width: 300px;
  // transition: all .2s linear;
  &--left {
    left: 10px;
  }
  &--right {
    right: 0;
  }
  &--sticky {
    top: 65px;
  }
}
.section.section-lab-sta {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  .lablist {
    flex: 0 0 868px;
    /deep/ .lab-list {
      height: 100%;
      .list {
        display: flex;
        flex-wrap: wrap;
        max-height: 300px;
        li {
          flex: 0 0 50%;
        }
      }
    }
  }
  .sta {
    flex: 0 0 300px;
    /deep/ .lab-list {
      height: 100%;
    }
  }
}
.fast-track {
  position: absolute;
  right: 0;
  bottom: -95px;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
